import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

function ProjectCard({ title, skills, description, projectImage, githubURL }) {
    return (
        <div className="bg-white rounded-lg shadow-md p-4 w-80 relative mx-8">
            <img src={projectImage} alt={title} className="w-full h-40 rounded-t-lg object-cover mb-4" />
            <div className="p-2">
                <h3 className="text-xl font-semibold mb-2">{title}</h3>
                <ul className="mb-3 space-y-1">
                    {skills.map(skill => (
                        <li key={skill} className="text-sm text-gray-500">{skill}</li>
                    ))}
                </ul>
                <p className="text-gray-600">{description}</p>
            </div>
            <a href={githubURL}  className="absolute bottom-2 right-2">
                <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
        </div>
    );
}

export default ProjectCard;
