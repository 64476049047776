import './App.css';

import { useFormik } from 'formik';

import {
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEnvelope,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';

import profile from '../assets/profile.png';
import resume from '../assets/resume.pdf';
import ProjectCard from '../components/ProjectCard';

function App() {
  const formik = useFormik({
    
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      message: '',
    }, 
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = 'Email required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address';
      }
  
      if (!values.phoneNumber) {
        errors.phoneNumber = 'Phone number required';
      } else if (
        !/^\d{10}$/i.test(values.phoneNumber)
      ) {
        errors.phoneNumber = 'Invalid phone number';
      }
  
      return errors;
    },
    
    onSubmit: async (values) => {
      // Send the values to Google Sheets or other logic here
    },
  });
  const navigateToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const headerOffset = document.querySelector('header').offsetHeight;
      const elementPosition = sectionElement.offsetTop;
      const offsetPosition = elementPosition - headerOffset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  



  return (
    <div className="App-links  bg-gradient-to-r from-slate-300 to-slate-500">
      <header className="sticky top-0 bg-white p-4 shadow-md z-10">
                <nav className="container mx-auto flex justify-end space-x-4 font-primary">
                    <button onClick={() => navigateToSection('about-section')}>About</button>
                    <button onClick={() => navigateToSection('projects-section')}>Projects</button>
                    <button onClick={() => navigateToSection('contact-section')}>Contact</button>
        <div className="border-l border-gray-400 h-5 mx-3"></div>

<button onClick={() => window.open('https://docs.google.com/spreadsheets/d/1zZz9w4SWqBmjL53rsPAidUZmeMAkH4IxSg7XMcweq4E/edit?usp=sharing', '_blank')}>Product Portfolio</button>
<button onClick={() => window.open('https://docs.google.com/spreadsheets/YOUR_LINK_2', '_blank')}>Design Portfolio</button>
                </nav>
            </header>
      <div className="mb-5 text-center">
      <Transition
          show={true}
          appear = {true}
          enter="transform transition duration-[1000ms]"
          enterFrom="opacity-0 rotate-[-120deg] scale-50"
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100 "
          leaveTo="opacity-0 scale-95 "
          className = "mb-5 text-center"
        >
        <h1  className="text-3xl font-primary mt-10 mb-5 text-center overflow-hidden whitespace-nowrap">Hello! My name is Srikar Manikonda</h1>
        </Transition>
        <h2 className='text-xl font-primary mb-5'>I am currently an ECE student at the University of Illinois. I am passionate about creating elegant and robust software.</h2>

        <div className="relative rounded-full overflow-hidden w-36 h-36 mx-auto mb-3 ">
    <img src={profile} alt="Srikar Manikonda" className="object-cover w-full h-full" />
</div>


       
      </div>

   
      <Transition
          show={true}
          appear = {true}
          enter="transform transition duration-[1000ms]"
          enterFrom="opacity-0 rotate-[-120deg] scale-50"
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100 "
          leaveTo="opacity-0 scale-95 "
          className = "mb-5 text-center"
        >
        <a href={resume} target="_blank" rel="noopener noreferrer" className='mx-2'>
          <FontAwesomeIcon icon={faFile} size="2x" />
        </a>
        <a href="mailto:smanikonda99@gmail.com" target="_blank" rel="noopener noreferrer" className='mx-2'>
          <FontAwesomeIcon icon={faEnvelope} size="2x" />
        </a>
        <a href="https://www.linkedin.com/in/srikar-manikonda-7b5606147/" target="_blank" rel="noopener noreferrer" className='mx-2'>
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        <a href="https://github.com/srikarmanikonda" target="_blank" rel="noopener noreferrer" className='mx-2'>
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
      </Transition>
      <div className="container mx-auto p-10 text-center" id="about-section">
    <h1 className='text-3xl mb-10 text-center font-primary'>About</h1>

    <p className="mb-6 text-xl">
        I primarily have a background in full-stack development and product management. I was previously an intern at Onetext (YC W23) as Intern #1 where I worked on product development and fullstack development. 
        
    </p>
    <p className="mb-6 text-xl">
      Although I have a technical background I love reading and writing on startups, the future of tech, and anything else that comes to mind. You can check out my writing <a href = "https://srikarmanikonda.substack.com/"><b><u>here</u></b> </a>          
    </p>
    <p className="mb-6 text-xl">
      Additionally, I'm an active angel investor. I love working with mission-driven and fast-moving  companies such as Depot (YC W23) and Linc AI (YCS23). You can read my investing thesis and learn more about how I source companies in the blog linked above. 
    </p>


 
</div>







      
      <div className='mt-10 mb-5' id="projects-section" >
      <h1 className='text-3xl mb-10 text-center font-primary'> Featured projects </h1>
      <div className="flex flex-row ">
            <ProjectCard
                title="Unify"
                skills={["Expo", "React Native", "Python"]}
                description="Cross platform mobile application to bring legislative and governmental data to the public transparently. "
                projectImage="https://via.placeholder.com/400"
                githubURL="https://github.com/srikarmanikonda/unify"
            />
            <ProjectCard
                title="Greentrack"
                skills={["Bootstrap", "CSS", "Javascript"]}
                description="Web app focusing on gamifying donations and awareness to climate change organizations."
                projectImage="https://via.placeholder.com/400"
                githubURL="https://github.com/srikarmanikonda/Greentrack"
            />
                <ProjectCard
                title="SafeRoads"
                skills={["Android Studio", "XML", "PostgresSQL"]}
                description="Android app focused on allowing communities to track and log road data for improvement"
                projectImage="https://via.placeholder.com/400"
                githubURL="https://github.com/srikarmanikonda/Greentrack"
            />
                <ProjectCard
                title="Dare To Empower Mobile Application"
                skills={["Expo", "React Native", "Firebase"]}
                description="Cross platform mobile application to aggregate resources and tools to help domestic abuse victims."
                projectImage="https://via.placeholder.com/400"
                githubURL="https://github.com/srikarmanikonda/Greentrack"
            />
        </div>
      </div>


      <div className="flex flex-col justify-center items-center h-screen"  id="contact-section">
      <h1 className='text-3xl text-center mb-4 font-primary'>Contact Me</h1> 

  <div className="w-1/2p-8 rounded mt">
    <h3 className='text-center mb-3 text-xl'>If you would like to chat, contact me @ 224-247-9387.</h3>
    {/*
    <form onSubmit={formik.handleSubmit} className='space-y-3'>
      <input type="text" name="name" placeholder="Name" onChange={formik.handleChange} value={formik.values.name} className='p-2 w-full' />
      <input type="email" name="email" placeholder="Email" onChange={formik.handleChange} value={formik.values.email} className='p-2 w-full rounded-lg' />
      <input type="tel" name="phoneNumber" placeholder="Phone Number" onChange={formik.handleChange} value={formik.values.phoneNumber} className='p-2 w-full rounded-lg' />
      <textarea name="message" placeholder="Message" onChange={formik.handleChange} value={formik.values.message} className='p-2 w-full rounded-lg' style={{ height: '100px' }} />
      <button type="submit" className='bg-blue-500 text-white p-2 w-full rounded-lg'>Submit</button>
    </form>
    */}
  </div>
</div>
</div>



  );
}

export default App;
